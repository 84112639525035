import { ref, defineComponent, watchEffect, computed } from 'vue';
import classNames from '../_util/classNames';
import PropTypes from '../_util/vue-types';
import CloseOutlined from '@ant-design/icons-vue/CloseOutlined';
import Wave from '../_util/wave';
import { PresetColorTypes, PresetStatusColorTypes } from '../_util/colors';
import CheckableTag from './CheckableTag';
import useConfigInject from '../_util/hooks/useConfigInject';
const PresetColorRegex = new RegExp(`^(${PresetColorTypes.join('|')})(-inverse)?$`);
const PresetStatusColorRegex = new RegExp(`^(${PresetStatusColorTypes.join('|')})$`);
const tagProps = {
    prefixCls: PropTypes.string,
    color: {
        type: String,
    },
    closable: PropTypes.looseBool.def(false),
    closeIcon: PropTypes.VNodeChild,
    visible: PropTypes.looseBool,
    onClose: {
        type: Function,
    },
    icon: PropTypes.VNodeChild,
};
const Tag = defineComponent({
    name: 'ATag',
    props: tagProps,
    emits: ['update:visible', 'close'],
    slots: ['closeIcon', 'icon'],
    setup(props, { slots, emit, attrs }) {
        const { prefixCls, direction } = useConfigInject('tag', props);
        const visible = ref(true);
        watchEffect(() => {
            if (props.visible !== undefined) {
                visible.value = props.visible;
            }
        });
        const handleCloseClick = (e) => {
            e.stopPropagation();
            emit('update:visible', false);
            emit('close', e);
            if (e.defaultPrevented) {
                return;
            }
            if (props.visible === undefined) {
                visible.value = false;
            }
        };
        const isPresetColor = computed(() => {
            const { color } = props;
            if (!color) {
                return false;
            }
            return PresetColorRegex.test(color) || PresetStatusColorRegex.test(color);
        });
        const tagClassName = computed(() => classNames(prefixCls.value, {
            [`${prefixCls.value}-${props.color}`]: isPresetColor.value,
            [`${prefixCls.value}-has-color`]: props.color && !isPresetColor.value,
            [`${prefixCls.value}-hidden`]: !visible.value,
            [`${prefixCls.value}-rtl`]: direction.value === 'rtl',
        }));
        return () => {
            var _a, _b, _c;
            const { icon = (_a = slots.icon) === null || _a === void 0 ? void 0 : _a.call(slots), color, closeIcon = (_b = slots.closeIcon) === null || _b === void 0 ? void 0 : _b.call(slots), closable = false, } = props;
            const renderCloseIcon = () => {
                if (closable) {
                    return closeIcon ? (<div class={`${prefixCls}-close-icon`} onClick={handleCloseClick}>
              {closeIcon}
            </div>) : (<CloseOutlined class={`${prefixCls}-close-icon`} onClick={handleCloseClick}/>);
                }
                return null;
            };
            const tagStyle = {
                backgroundColor: color && !isPresetColor.value ? color : undefined,
            };
            const iconNode = icon || null;
            const children = (_c = slots.default) === null || _c === void 0 ? void 0 : _c.call(slots);
            const kids = iconNode ? (<>
          {iconNode}
          <span>{children}</span>
        </>) : (children);
            const isNeedWave = 'onClick' in attrs;
            const tagNode = (<span class={tagClassName.value} style={tagStyle}>
          {kids}
          {renderCloseIcon()}
        </span>);
            return isNeedWave ? <Wave>{tagNode}</Wave> : tagNode;
        };
    },
});
Tag.CheckableTag = CheckableTag;
Tag.install = function (app) {
    app.component(Tag.name, Tag);
    app.component(CheckableTag.name, CheckableTag);
    return app;
};
export { CheckableTag };
export default Tag;
